import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  PERCEIVED_BENEFITS_Q6_1_LIST,
  QUESTION_6,
} from "../../constants/constants.js";
import { Card, Table } from "react-bootstrap";

import { useContext } from "react";
import { ColorPaletteContext } from "../../App.js";
import "../../App.css";
import SummarySpan from "../SummarySpan.js";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

function PerceivedBenefitsBarChartQuestion6(props) {
  const colorPaletteContext = useContext(ColorPaletteContext);
  const { colorPaletteState, updateColorPaletteState } = colorPaletteContext;

  const labels = [
    { key: 1, value: "1-I don't anticipate this impact" },
    { key: 2, value: "2" },
    { key: 3, value: "3" },
    { key: 4, value: "4" },
    { key: 5, value: "5" },
    { key: 6, value: "6" },
    { key: 7, value: "7-I anticipate this impact" },
  ];

  function getAveragePercentageForEachOption() {
    return [
      ["7", "6", "15", "17", "31", "21", "3"],
      ["3", "14", "12", "14", "27", "22", "8"],
      ["6", "12", "11", "13", "22", "27", "9"],
      ["6", "9", "11", "15", "24", "26", "9"],
      ["4", "10", "11", "16", "24", "25", "11"],
      ["5", "10", "10", "16", "26", "24", "10"],
      ["4", "10", "17", "17", "18", "24", "10"],
      ["4", "14", "10", "11", "25", "29", "7"],
    ];
  }

  function getOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0, // Add left padding
          right: 0, // Add right padding
          top: 50, // Add top padding
          bottom: 5, // Add bottom padding
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (context) => {
              return context[0].label.replaceAll(",", " ");
            },
            label: (tooltipItem) => {
              return tooltipItem.formattedValue + "%";
            },
          },
        },
        legend: {
          display: true,
          position: "bottom",
          onClick: null, // Disable legend item click event
        },
        datalabels: {
          rotation: -90,
          formatter: (value) => {
            if (value) {
              return value + " %";
            }
          },
          anchor: "end",
          align: "top",
          //offset: "0",
          color: "grey",
          font: {
            weight: "bold",
            size: 13,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 8,
            },
          },
        },
        y: {
          grid: {
            display: false,
          },
          display: false,
        },
      },
    };
  }

  function getChartData() {
    return {
      labels: PERCEIVED_BENEFITS_Q6_1_LIST.map((item, index) => item.value),
      datasets: labels.map((label, index) => ({
        label: label.value,
        data: getAveragePercentageForEachOption().map(
          (questionResponses) => questionResponses[index]
        ),
        backgroundColor: colorPaletteState[index],
      })),
    };
  }

  return (
    <Card>
      <Card.Header>{QUESTION_6}</Card.Header>
      <Card.Body>
        <div style={{ width: "100%", height: "60vh" }}>
          <Bar options={getOptions()} data={getChartData()} />
        </div>
        <SummarySpan />
        <Table striped bordered hover size="sm">
          <thead className="custom-font-theading">
            <tr>
              <th></th>
              <th colspan="2">Question1</th>
              <th colspan="2">Question2</th>
              <th colspan="2">Question3</th>
              <th colspan="2">Question4</th>
              <th colspan="2">Question5</th>
              <th colspan="2">Question6</th>
              <th colspan="2">Question7</th>
              <th colspan="2">Question8</th>
            </tr>
          </thead>
          <tbody className="custom-font-tbody">
            <tr>
              <td>Rating</td>
              <td>Submission</td>
              <td>%</td>

              <td>Submission</td>
              <td>%</td>

              <td>Submission</td>
              <td>%</td>

              <td>Submission</td>
              <td>%</td>

              <td>Submission</td>
              <td>%</td>

              <td>Submission</td>
              <td>%</td>

              <td>Submission</td>
              <td>%</td>

              <td>Submission</td>
              <td>%</td>
            </tr>
            <tr>
              <td>1</td>
              <td>14</td>
              <td>7 %</td>

              <td>6</td>
              <td>3 %</td>

              <td>12</td>
              <td>6 %</td>

              <td>12</td>
              <td>6 %</td>

              <td>8</td>
              <td>4 %</td>

              <td>10</td>
              <td>5 %</td>

              <td>8</td>
              <td>4%</td>

              <td>8</td>
              <td>4 %</td>
            </tr>
            <tr>
              <td>2</td>
              <td>12</td>
              <td>6 %</td>

              <td>28</td>
              <td>14 %</td>

              <td>24</td>
              <td>12 %</td>

              <td>18</td>
              <td>9 %</td>

              <td>20</td>
              <td>10 %</td>

              <td>20</td>
              <td>10 %</td>

              <td>20</td>
              <td>10 %</td>

              <td>28</td>
              <td>14 %</td>
            </tr>
            <tr>
              <td>3</td>
              <td>30</td>
              <td>15 %</td>

              <td>24</td>
              <td>12 %</td>

              <td>22</td>
              <td>11 %</td>

              <td>22</td>
              <td>11 %</td>

              <td>22</td>
              <td>11 %</td>

              <td>20</td>
              <td>10 %</td>

              <td>34</td>
              <td>17 %</td>

              <td>20</td>
              <td>10 %</td>
            </tr>
            <tr>
              <td>4</td>
              <td>34</td>
              <td>17 %</td>

              <td>28</td>
              <td>14 %</td>

              <td>26</td>
              <td>13 %</td>

              <td>30</td>
              <td>15 %</td>

              <td>32</td>
              <td>16 %</td>

              <td>32</td>
              <td>16 %</td>

              <td>34</td>
              <td>17 %</td>

              <td>22</td>
              <td>11 %</td>
            </tr>
            <tr>
              <td>5</td>
              <td>62</td>
              <td>31 %</td>

              <td>54</td>
              <td>27 %</td>

              <td>44</td>
              <td>22 %</td>

              <td>48</td>
              <td>24 %</td>

              <td>48</td>
              <td>24 %</td>

              <td>52</td>
              <td>26 %</td>

              <td>36</td>
              <td>18 %</td>

              <td>50</td>
              <td>25 %</td>
            </tr>
            <tr>
              <td>6</td>
              <td>42</td>
              <td>21 %</td>

              <td>44</td>
              <td>22 %</td>

              <td>54</td>
              <td>27 %</td>

              <td>52</td>
              <td>26 %</td>

              <td>50</td>
              <td>25 %</td>

              <td>48</td>
              <td>24 %</td>

              <td>48</td>
              <td>24 %</td>

              <td>58</td>
              <td>29 %</td>
            </tr>
            <tr>
              <td>7</td>
              <td>6</td>
              <td>3 %</td>

              <td>16</td>
              <td>8 %</td>

              <td>18</td>
              <td>9 %</td>

              <td>18</td>
              <td>9 %</td>

              <td>22</td>
              <td>11 %</td>

              <td>20</td>
              <td>10 %</td>

              <td>20</td>
              <td>10 %</td>

              <td>14</td>
              <td>7 %</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
export default PerceivedBenefitsBarChartQuestion6;
