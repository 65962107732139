import React from "react";

function SummarySpan() {
  return (
    <span
      style={{
        color: "black",
        fontSize: 20,
        textDecoration: "underline",
      }}
    >
      Chart Summary:
    </span>
  );
}

export default SummarySpan;
