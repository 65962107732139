import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Card, Table } from "react-bootstrap";
import {
  QUESTION_7,
  SOFTWARE_ADOPTION_BAR_CHART_DATA,
  SOFTWARE_ADOPTION_LABELS,
  SOFTWARE_ADOPTION_LIST,
} from "../../constants/constants";
import { useContext } from "react";
import { ColorPaletteContext } from "../../App";
import SummarySpan from "../SummarySpan";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

const StackedBarChartQuestion7 = (props) => {
  const colorPaletteContext = useContext(ColorPaletteContext);
  const { colorPaletteState } = colorPaletteContext;

  function getAveragePercentageForEachOption(
    index,
    softwareAdoptionIndex,
    resultArray = []
  ) {
    if (props.countryFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q0 === props.countryFilterValue
      );
    }

    var test = resultArray.filter(
      (obj) =>
        obj[`Q7_${softwareAdoptionIndex + 1}`] === "1" && obj.Q7 === index
    );

    var temp = (
      (test.length / resultArray.filter((obj) => obj.Q7 === index).length) *
      100
    ).toFixed(0);

    return temp;
  }

  const data = {
    labels: SOFTWARE_ADOPTION_LABELS.map((item) => item.value),
    datasets: SOFTWARE_ADOPTION_LIST.map((item, adoptionIndex) => ({
      label: item.value,
      data: SOFTWARE_ADOPTION_LABELS.map((obj, index) =>
        getAveragePercentageForEachOption(
          obj.key,
          adoptionIndex,
          SOFTWARE_ADOPTION_BAR_CHART_DATA
        )
      ),
      backgroundColor: colorPaletteState[adoptionIndex],
    })),
  };

  function getOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0, // Add left padding
          right: 0, // Add right padding
          top: 30, // Add top padding
          bottom: 5, // Add bottom padding
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          title: { display: true, padding: 10 },
          onClick: null, // Disable legend item click event
          labels: {
            font: {
              //size: 12,
              //weight: "bold",
            },
          },
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              return context[0].label.replaceAll(",", " ");
            },
            label: (tooltipItem) => {
              return tooltipItem.formattedValue + "%";
            },
          },
        },
        datalabels: {
          display: function (context) {
            return context.dataset.data[context.dataIndex] >= 1;
          },
          formatter: (value) => {
            return value + " %";
          },
          anchor: "center",
          align: "center",
          color: "white",
          font: {
            weight: "bold",
            size: 13,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
        },
      },
    };
  }

  return (
    <Card>
      <Card.Header>{QUESTION_7}</Card.Header>
      <Card.Body>
        <div style={{ width: "100%", height: "60vh" }}>
          <Bar data={data} options={getOptions()} />
        </div>
        <SummarySpan />
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th></th>
              <th colspan="2">Software A</th>
              <th colspan="2">Software B</th>
              <th colspan="2">Software C</th>
              <th colspan="2">Software D</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Rating</td>
              <td>Submission</td>
              <td>%</td>

              <td>Submission</td>
              <td>%</td>

              <td>Submission</td>
              <td>%</td>

              <td>Submission</td>
              <td>%</td>
            </tr>
            <tr>
              <td>Not Started</td>
              <td>66</td>
              <td>33 %</td>

              <td>28</td>
              <td>14 %</td>

              <td>114</td>
              <td>57 %</td>

              <td>112</td>
              <td>56 %</td>
            </tr>
            <tr>
              <td>Adoption In-Progress</td>
              <td>34</td>
              <td>17 %</td>

              <td>114</td>
              <td>57 %</td>

              <td>58</td>
              <td>29 %</td>

              <td>44</td>
              <td>22 %</td>
            </tr>
            <tr>
              <td>Full Adoption</td>
              <td>100</td>
              <td>50 %</td>

              <td>58</td>
              <td>29 %</td>

              <td>28</td>
              <td>14 %</td>

              <td>44</td>
              <td>22 %</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default StackedBarChartQuestion7;
