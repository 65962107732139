import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import ManufacturerPreferenceBarChartQuestion8 from "./ManufacturerPreferenceBarChartQuestion8";
import ManufacturerPreferenceBarChartQuestion9 from "./ManufacturerPreferenceBarChartQuestion9";
import { FilterContext, FilteredQuestionListContext } from "../../App";
import { PRICING_PREFERENCES_QUESTIONS } from "../../constants/constants";

function PricingPreference() {
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  return (
    <div>
      {(filteredQuestionListContext.state.allQuestions ||
        PRICING_PREFERENCES_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          {(filteredQuestionListContext.state.allQuestions ||
            ["Q8"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              <Col>
                <ManufacturerPreferenceBarChartQuestion8
                  countryFilterValue={filterContext.state.countryFilterValue}
                />
              </Col>
            </Row>
          )}

          {(filteredQuestionListContext.state.allQuestions ||
            ["Q9"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              <Col>
                <ManufacturerPreferenceBarChartQuestion9
                  countryFilterValue={filterContext.state.countryFilterValue}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
}

export default PricingPreference;
