import Select from "react-select";
import { useContext } from "react";
import { FilteredQuestionListContext } from "../App";
import {
  QUESTION_1,
  QUESTION_2,
  QUESTION_3,
  QUESTION_4,
  QUESTION_5,
  QUESTION_6,
  QUESTION_7,
  QUESTION_8,
  QUESTION_9,
} from "../constants/constants";

function QuestionsDropDown() {
  var kpis = ["Q1", "Q2", "Q3", "Q4", "Q6", "Q7", "Q8", "Q9"];

  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  const { filteredQuestionListState, updateFilteredQuestionListState } =
    filteredQuestionListContext;

  function getQuestionList() {
    return [
      {
        label: QUESTION_1,
        value: "Q1",
      },
      {
        label: QUESTION_2,
        value: "Q2",
      },
      {
        label: QUESTION_3,
        value: "Q3",
      },
      {
        label: QUESTION_4,
        value: "Q4",
      },
      {
        label: QUESTION_5,
        value: "Q5",
      },
      {
        label: QUESTION_6,
        value: "Q6",
      },
      {
        label: QUESTION_7,
        value: "Q7",
      },
      {
        label: QUESTION_8,
        value: "Q8",
      },
      {
        label: QUESTION_9,
        value: "Q9",
      },
    ];
  }

  return (
    <div>
      <Select
        name="questions"
        options={getQuestionList()}
        isClearable={true}
        onChange={(event) =>
          updateFilteredQuestionListState(event.map((obj) => obj.value))
        }
        isMulti
      />
    </div>
  );
}

export default QuestionsDropDown;
