import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  COLORS_RYGU,
  QUESTION_8,
  MANUFACTURER_PERFERENCE_Q8_LIST,
  MANUFACTURER_PREFERENCE_BAR_CHART_DATA,
} from "../../constants/constants.js";
import { Card, Table } from "react-bootstrap";
import { ColorPaletteContext } from "../../App.js";
import { useContext } from "react";
import SummarySpan from "../SummarySpan.js";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

function ManufacturerPreferenceBarChartQuestion8(props) {
  //const { dashboardData } = useAPI();

  const colorPaletteContext = useContext(ColorPaletteContext);
  const { colorPaletteState } = colorPaletteContext;

  function getOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0, // Add left padding
          right: 0, // Add right padding
          top: 30, // Add top padding
          bottom: 5, // Add bottom padding
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (context) => {
              return context[0].label.replaceAll(",", " ");
            },
            label: (tooltipItem) => {
              return tooltipItem.formattedValue + "%";
            },
          },
        },
        legend: {
          display: false,
        },
        datalabels: {
          formatter: (value) => {
            return value + " %";
          },
          anchor: "end",
          align: "top",
          color: "grey",
          font: {
            weight: "bold",
            size: 13,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
          //display: false,
        },
      },
    };
  }

  function getAveragePercentageForEachOption(resultArray = []) {
    if (props.countryFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q0 === props.countryFilterValue
      );
    }

    var test = MANUFACTURER_PERFERENCE_Q8_LIST.map(
      (item, index) =>
        resultArray.filter((obj) => obj.Q8 === item.realValue).length
    );

    if (resultArray.length === 0) {
      return [0, 0, 0, 0, 0, 0, 0];
    }

    return test.map((item, index) => {
      return ((Number(item) / resultArray.length) * 100).toFixed(0);
    });
  }

  function getChartData() {
    return {
      labels: MANUFACTURER_PERFERENCE_Q8_LIST.map((item, index) => item.value),
      datasets: [
        {
          label: "Manufacturer preferences",
          data: getAveragePercentageForEachOption(
            MANUFACTURER_PREFERENCE_BAR_CHART_DATA
          ),
          borderColor: colorPaletteState[0],
          backgroundColor: colorPaletteState[0],
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }

  return (
    <Card>
      <Card.Header>{QUESTION_8}</Card.Header>
      <Card.Body>
        <div style={{ width: "100%", height: "60vh" }}>
          <Bar options={getOptions()} data={getChartData()} />
        </div>
        <SummarySpan />
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Level of satisfaction</th>
              <th>Submission</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Very likely</td>
              <td>72</td>
              <td>36 %</td>
            </tr>
            <tr>
              <td>Likely</td>
              <td>46</td>
              <td>23 %</td>
            </tr>
            <tr>
              <td>Somewhat likely</td>
              <td>18</td>
              <td>9 %</td>
            </tr>
            <tr>
              <td>Neutral</td>
              <td>18</td>
              <td>9 %</td>
            </tr>
            <tr>
              <td>Somewhat unlikely</td>
              <td>10</td>
              <td>5 %</td>
            </tr>
            <tr>
              <td>Unlikely</td>
              <td>10</td>
              <td>5 %</td>
            </tr>
            <tr>
              <td> Very Unlikely</td>
              <td>28</td>
              <td>14 %</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
export default ManufacturerPreferenceBarChartQuestion8;
