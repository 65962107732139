import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  QUESTION_2,
  SALES_BY_REGION_DATA,
  SALES_BY_REGION_LABELS,
} from "../../constants/constants";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { Card } from "react-bootstrap";
import { ColorPaletteContext } from "../../App";
import { useContext } from "react";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD", // Change this to the desired currency code
  minimumFractionDigits: 2, // Specify the minimum number of decimal places
});

const DoughnutChartQuestion2 = (props) => {
  const colorPaletteContext = useContext(ColorPaletteContext);

  const { colorPaletteState } = colorPaletteContext;

  function getSalesByRegionData(arr = []) {
    let finalArray = arr;

    if (props.countryFilterValue) {
      finalArray = finalArray.filter(
        (item) => item.Q0 === props.countryFilterValue
      );
    }

    return finalArray.map((item) => item.Q2);
  }

  const data = {
    labels: SALES_BY_REGION_LABELS.map((item, index) => item.value),
    datasets: [
      {
        data: getSalesByRegionData(SALES_BY_REGION_DATA),
        borderColor: colorPaletteState,
        backgroundColor: colorPaletteState,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0, // Add left padding
        right: 0, // Add right padding
        top: 20, // Add top padding
        bottom: 5, // Add bottom padding
      },
    },
    cutoutPercentage: 50, // Adjust this to control the size of the hole in the doughnut
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem, data) => {
            return formatter.format(tooltipItem.raw);
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        title: { display: true, padding: 10 },
        onClick: null, // Disable legend item click event
        labels: {
          font: {
            //size: 12,
            //weight: "bold",
          },
        },
      },
      datalabels: {
        formatter: (value, context) => {
          if (context.dataset.data[context.dataIndex] > 0) {
            return formatter.format(value);
          } else {
            return "";
          }
        },
        display: true,
        anchor: "center",
        align: "center",
        color: "white",
        font: {
          weight: "bold",
          size: 13,
        },
      },
    },
    elements: {
      arc: {
        spacing: 5,
      },
    },
  };

  return (
    <Card>
      <Card.Header>{QUESTION_2}</Card.Header>
      <Card.Body>
        <div style={{ width: "100%", height: "60vh" }}>
          <Doughnut data={data} options={options} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default DoughnutChartQuestion2;
