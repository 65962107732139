import React, { useContext } from "react";
import { FilterContext, FilteredQuestionListContext } from "../../App";
import { Col, Row } from "react-bootstrap";
import PieChartQuestion1 from "./PieChartQuestion1";
import DoughnutChartQuestion2 from "./DoughnutChartQuestion2";
import LineChartQuestion3 from "./LineChartQuestion3";
import AreaChartQuestion4 from "./AreaChartQuestion4";
import RatingProductFeatureBarChartQuestion5 from "./RatingProductFeatureBarChartQuestion5";
import PerceivedBenefitsBarChartQuestion6 from "./PerceivedBenefitsBarChartQuestion6";
import StackedBarChartQuestion7 from "./StackedBarChartQuestion7";
import { SCRENNER_QUESTIONS } from "../../constants/constants";

function Screener() {
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  return (
    <div>
      {(filteredQuestionListContext.state.allQuestions ||
        SCRENNER_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          {(filteredQuestionListContext.state.allQuestions ||
            ["Q1", "Q2"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              {filteredQuestionListContext.state.allQuestions && (
                <>
                  <Col sm={12} md={6}>
                    <PieChartQuestion1
                      countryFilterValue={
                        filterContext.state.countryFilterValue
                      }
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <DoughnutChartQuestion2
                      countryFilterValue={
                        filterContext.state.countryFilterValue
                      }
                    />
                  </Col>
                </>
              )}

              {filteredQuestionListContext.state.filteredQuestions.includes(
                "Q1"
              ) && (
                <Col>
                  <PieChartQuestion1
                    countryFilterValue={filterContext.state.countryFilterValue}
                  />
                </Col>
              )}

              {filteredQuestionListContext.state.filteredQuestions.includes(
                "Q2"
              ) && (
                <Col>
                  <DoughnutChartQuestion2
                    countryFilterValue={filterContext.state.countryFilterValue}
                  />
                </Col>
              )}
            </Row>
          )}

          {(filteredQuestionListContext.state.allQuestions ||
            ["Q3"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              <Col>
                <LineChartQuestion3
                  countryFilterValue={filterContext.state.countryFilterValue}
                />
              </Col>
            </Row>
          )}

          {(filteredQuestionListContext.state.allQuestions ||
            ["Q4"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              <Col>
                <AreaChartQuestion4 />
              </Col>
            </Row>
          )}

          {(filteredQuestionListContext.state.allQuestions ||
            ["Q5"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              <Col>
                <RatingProductFeatureBarChartQuestion5
                  countryFilterValue={filterContext.state.countryFilterValue}
                />
              </Col>
            </Row>
          )}

          {(filteredQuestionListContext.state.allQuestions ||
            ["Q6"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              <Col>
                <PerceivedBenefitsBarChartQuestion6 />
              </Col>
            </Row>
          )}

          {(filteredQuestionListContext.state.allQuestions ||
            ["Q7"].some((element) =>
              filteredQuestionListContext.state.filteredQuestions.includes(
                element
              )
            )) && (
            <Row>
              <Col>
                <StackedBarChartQuestion7
                  countryFilterValue={filterContext.state.countryFilterValue}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
}

export default Screener;
