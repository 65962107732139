import React, { useContext } from "react";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";

import { Container, FormGroup, FormLabel } from "react-bootstrap";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { FilterContext, FilteredQuestionListContext } from "../App";
import Screener from "./Screener/Screener";
import Collapsible from "react-collapsible";
import {
  PRICING_PREFERENCES_QUESTIONS,
  SCRENNER_QUESTIONS,
} from "../constants/constants";
import PricingPreference from "./PricingPreference/PricingPreference";
import QuestionsDropDown from "./QuestionsDropDown";
import BackToTopButton from "./BackToButton";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

function Home() {
  const filterContext = useContext(FilterContext);
  const filteredQuestionListContext = useContext(FilteredQuestionListContext);

  return (
    <Container>
      <FormGroup>
        <FormLabel className="mt-3">Select Question(s)</FormLabel>
        <QuestionsDropDown />
      </FormGroup>
      {(filteredQuestionListContext.state.allQuestions ||
        SCRENNER_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Collapsible
            trigger={
              <>
                <div className="line-text">
                  <span>SCREENER</span>
                </div>
              </>
            }
            open={true}
            transitionTime={600}
          >
            <Screener />
          </Collapsible>
        </>
      )}

      {(filteredQuestionListContext.state.allQuestions ||
        PRICING_PREFERENCES_QUESTIONS.some((element) =>
          filteredQuestionListContext.state.filteredQuestions.includes(element)
        )) && (
        <>
          <Collapsible
            trigger={
              <>
                <div className="line-text">
                  <span>PRICING PREFERENCES</span>
                </div>
              </>
            }
            open={true}
            transitionTime={600}
          >
            <PricingPreference />
          </Collapsible>
        </>
      )}
      <BackToTopButton />
    </Container>
  );
}

export default Home;
