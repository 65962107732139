import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import { ThemeProvider } from "styled-components";
import Header from "./components/Header";
import { GlobalStyle } from "./GlobalStyle";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { Container } from "react-bootstrap";
import { COLORS_RYGU } from "./constants/constants";

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

export const FilterContext = React.createContext();
export const ColorPaletteContext = React.createContext();
export const FilteredQuestionListContext = React.createContext();

function App() {
  const initialColorPaletteState = COLORS_RYGU;

  const [colorPaletteState, setColorPaletteState] = useState(
    initialColorPaletteState
  );

  const updateColorPaletteState = (updatedColorPalette) => {
    setColorPaletteState(updatedColorPalette);
  };

  const initialFilterState = {
    countryFilterLabel: "",
    countryFilterValue: "",
  };

  const [filterState, setFilterState] = useState(initialFilterState);

  const updateFilterState = (updatedFilterState) => {
    setFilterState(updatedFilterState);
  };

  const initialFilteredQuestionListState = {
    allQuestions: true,
    filteredQuestions: [],
  };

  const [filteredQuestionListState, setFilteredQuestionListState] = useState(
    initialFilteredQuestionListState
  );

  const updateFilteredQuestionListState = (filteredQuestions) => {
    setFilteredQuestionListState({
      ...filteredQuestionListState,
      filteredQuestions,
      allQuestions: filteredQuestions.length == 0,
    });
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  Amplify.configure({
    Auth: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
    },
  });

  const theme = {
    colors: {
      heading: "rgb(24 24 29)",
      text: "rgba(29 ,29, 29, .8)",
      white: "#fff",
      black: " #212529",
      helper: "#8490ff",

      bg: "#666666",
      footer_bg: "#0a1435",
      btn: "rgb(98 84 243)",
      border: "rgba(98, 84, 243, 0.5)",
      hr: "#ffffff",
      gradient:
        "linear-gradient(0deg, rgb(132 144 255) 0%, rgb(98 189 252) 100%)",
      shadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      shadowSupport: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    media: {
      mobile: "768px",
      tab: "998px",
    },
  };

  return (
    <>
      <FilteredQuestionListContext.Provider
        value={{
          state: filteredQuestionListState,
          updateFilteredQuestionListState,
        }}
      >
        <ColorPaletteContext.Provider
          value={{ colorPaletteState, updateColorPaletteState }}
        >
          <FilterContext.Provider
            value={{ state: filterState, updateFilterState }}
          >
            <div className="main">
              <ThemeProvider theme={theme}>
                <Router>
                  <GlobalStyle />
                  <Header />
                  <Container></Container>
                  <Routes>
                    <Route path="/" element={<Home />} />
                  </Routes>
                </Router>
              </ThemeProvider>
            </div>
          </FilterContext.Provider>
        </ColorPaletteContext.Provider>
      </FilteredQuestionListContext.Provider>
    </>
  );
}

// export default withAuthenticator(App, {
//   hideSignUp: true,
//   hideForgotPassword: true,
// });

export default App;
